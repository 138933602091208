.salespark-cssreset html {
  color: #000;
  background: #fff;
}
.salespark-cssreset body,
.salespark-cssreset div,
.salespark-cssreset dl,
.salespark-cssreset dt,
.salespark-cssreset dd,
.salespark-cssreset ul,
.salespark-cssreset ol,
.salespark-cssreset li,
.salespark-cssreset h1,
.salespark-cssreset h2,
.salespark-cssreset h3,
.salespark-cssreset h4,
.salespark-cssreset h5,
.salespark-cssreset h6,
.salespark-cssreset pre,
.salespark-cssreset code,
.salespark-cssreset form,
.salespark-cssreset fieldset,
.salespark-cssreset legend,
.salespark-cssreset input,
.salespark-cssreset textarea,
.salespark-cssreset p,
.salespark-cssreset blockquote,
.salespark-cssreset th,
.salespark-cssreset td {
  margin: 0;
  padding: 0;
  color: black;
}
.salespark-cssreset table {
  border-collapse: collapse;
  border-spacing: 0;
}
.salespark-cssreset fieldset,
.salespark-cssreset img {
  border: 0;
}
.salespark-cssreset address,
.salespark-cssreset caption,
.salespark-cssreset cite,
.salespark-cssreset code,
.salespark-cssreset dfn,
.salespark-cssreset em,
.salespark-cssreset strong,
.salespark-cssreset th,
.salespark-cssreset var {
  font-style: normal;
  font-weight: normal;
}
.salespark-cssreset ol,
.salespark-cssreset ul {
  list-style: none;
}
.salespark-cssreset caption,
.salespark-cssreset th {
  text-align: left;
}
.salespark-cssreset h1,
.salespark-cssreset h2,
.salespark-cssreset h3,
.salespark-cssreset h4,
.salespark-cssreset h5,
.salespark-cssreset h6 {
  font-size: 100%;
  font-weight: normal;
}
.salespark-cssreset q:before,
.salespark-cssreset q:after {
  content: "";
}
.salespark-cssreset abbr,
.salespark-cssreset acronym {
  border: 0;
  font-variant: normal;
}
.salespark-cssreset sup {
  vertical-align: text-top;
}
.salespark-cssreset sub {
  vertical-align: text-bottom;
}
.salespark-cssreset input,
.salespark-cssreset textarea,
.salespark-cssreset select {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
}
.salespark-cssreset input,
.salespark-cssreset textarea,
.salespark-cssreset select {
  *font-size: 100%;
}
.salespark-cssreset legend {
  color: #000;
}

.salespark-cssreset ul {
  list-style-type: disc;
  list-style-position: inside;
}
.salespark-cssreset ol {
  list-style-type: decimal;
  list-style-position: inside;
}
.salespark-cssreset ul ul,
.salespark-cssreset ol ul {
  list-style-type: circle;
  list-style-position: inside;
  margin-left: 15px;
}
.salespark-cssreset ol ol,
.salespark-cssreset ul ol {
  list-style-type: lower-latin;
  list-style-position: inside;
  margin-left: 15px;
}
#yui3-css-stamp.cssreset-context {
  display: none;
}

.loading.cover-content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loading.cover-page {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading .ant-spin {
  color: #3e79f7;
}
.ant-tabs-tab {
  margin: 0px 0px 8px !important;
}
.fade-in {
  -webkit-animation: fadeIn 2s;
  animation: fadeIn 2s;
  opacity: 1;
}
.fade-in-2 {
  -webkit-animation: fadeIn 0.5s;
  animation: fadeIn 0.5s;
  opacity: 1;
}
@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
.white-link {
  color: #fff;
}
.white-link:hover {
  color: #e0eaff;
}
.btn-smaller {
  width: 32px !important;
  height: 32px !important;
  padding: 6.5px 0;
  font-size: 14px;
  border-radius: 0.625rem;
}
.entry-statistic-oldprice {
  text-decoration: line-through;
  color: #919191;
  font-size: 75%;
}
.update-notification-popup {
  position: fixed;
  bottom: 24px;
  left: 24px !important;
  box-shadow: 0px 0px 20px 6px rgba(184, 184, 184, 0.75);
  border-radius: 6px;
  background-color: #fff;
  padding: 18px;
  display: flex;
  max-width: calc(100vw - 48px);
  width: 300px;
  z-index: 9999;
}
.update-notification-popup button {
  background-color: #2977f6;
  border-radius: 4px;
  color: #fff;
  border: 0;
  padding: 0 16px;
  font-size: 0.9rem;
  height: 34px;
  font-weight: 600;
  margin-top: 10px;
  display: inline-block;
}
.code-box-demo {
  padding: 20px 24px 24px !important;
  border-bottom: 1px solid #e6ebf1;
}
@-webkit-keyframes msPulseEffect {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
@keyframes msPulseEffect {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.5);
  }
  100% {
    box-shadow: 0 0 0 8px rgba(0, 0, 0, 0);
  }
}
.inputGlow {
  min-width: 80px;
  margin-left: 5px;
  margin-right: 5px;
}
.inputGlow input,
.inputGlowSecondary input {
  text-align: center;
}
.inputGlowSecondary {
  width: 80px;
  max-width: 80px;
  text-align: center;
}
.inputGlow:hover,
.inputGlow:focus {
  -webkit-animation: msPulseEffect 1s infinite;
  animation: msPulseEffect 1s infinite;
}
.magicDateHelperList {
  padding-right: 15px;
  color: #3e79f7;
}
.magicDateInfoError {
  font-weight: 600;
  color: #ad0d15;
}
.magicDateInfoSuccess {
  font-weight: 600;
  color: #0d8e15;
}
.row-day {
  background-color: #f2f2f2;
}
#table-bank-transactions .ant-table-tbody > tr.ant-table-row:hover > td,
#table-bank-movements .ant-table-tbody > tr.ant-table-row:hover > td {
  background: unset !important;
}
.table-row-green {
  background-color: #ddffdd !important;
}
.table-row-red {
  background-color: #ffffff !important;
}
.table-row-yellow {
  background-color: #fff7c7 !important;
}
.table-row-green:hover,
.table-row-green:hover > td {
  background-color: #e2ffe2 !important;
}
.table-row-red:hover,
.table-row-red:hover > td {
  background-color: #fff8f8 !important;
}
.table-row-yellow:hover,
.table-row-yellow:hover > td {
  background-color: #ffffe0 !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  border-bottom: 0px !important;
  border-radius: 6px 6px 0px 0px !important;
}
.ql-editor {
  min-height: 50vh;
  overflow: visible !important;
}
.ql-container {
  border-radius: 0px 0px 6px 6px !important;
}
.ql-tooltip {
  left: 0 !important;
}

.ql-insertCustomTags {
  width: 280px !important;
}
.ql-picker.ql-insertCustomTags {
  width: 90px;
}
.ql-insertCustomTags .ql-picker-label:before {
  content: attr(data-label);
}

.ql-insertCustomTags .ql-picker-item {
  font-size: 11px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}
.ql-insertCustomTags .ql-picker-item:before {
  content: attr(data-label);
}
.btn-right {
  margin-right: 10px;
}
